<template>
  <div
    class="text-field"
    :class="{
      'text-field--rounded': rounded,
      'text-field--shadow': shadow,
      'text-field--search': theme == 'search',
    }"
  >
    <icons
      :icon="iconLeft"
      v-if="iconLeft !== ''"
      class="text-field__ic-left"
      color="#00A4B3"
    />
    <input
      type="text"
      :placeholder="placeholder"
      @click="$emit('click')"
      @input="$emit('input', $event.target.value)"
    />
    <icons
      :icon="iconRight"
      v-if="iconRight !== ''"
      class="text-field__ic-right"
      color="#00A4B3"
    />
  </div>
</template>

<script>
import icons from "@/components/elements/Icons.vue";
export default {
  components: { icons },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    theme: {
      type: String,
      default: "",
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    iconRight: {
      type: String,
      default: "",
    },
    iconLeft: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/color.scss";
.text-field {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
  input {
    width: 100%;
    border: none;
    outline: none;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  &--rounded {
    border-radius: 10px;
  }
  &--shadow {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  }
  &--search {
    background: $gray_9;
  }
  &__ic-left {
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__ic-right {
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
