<template>
  <div class="home">
    <v-card
      class="card-cart mt-0 mb-3 mt-md-4 mb-md-4 mr-md-4 ml-md-4 d-none d-sm-block"
    >
      <div class="section">
        <v-row>
          <v-col class="text-field ml-2 mr-auto" cols="8" md="8" lg="8">
            <text-field
              iconRight="search"
              theme="search"
              rounded
              shadow
              placeholder="Search Nearest Lab, Doctor, or Symptoms.."
            />
          </v-col>
          <v-col cols="1" class="mb-2 mr-0">
            <Cart
              class="cart-right"
              :class="{
                'd-none': !showCart,
                'd-md-flex': showCartDesktop,
                'd-md-none': !showCartDesktop,
              }"
            />
          </v-col>
          <v-col cols="1" class="icon d-md-none d-lg-none">
            <notification class="bell-mobile" />
          </v-col>
          <v-col cols="2" class="user-card d-md-none d-lg-none">
            <user-card type="patient" :hideDetail="true" @click="openDialog()">
            </user-card>
          </v-col>
        </v-row>
        <DialogMenu
          :tampilkan="dialogMenu"
          @toggleDialog="(dialog) => toggleDialogMenu(dialog)"
        />
      </div>
    </v-card>
    <custom-section
      class="section pt-0 pa-3 pa-md-5 d-md-none mt-12 mt-md-0 mt-lg-0"
    >
      <NavbarOne
        class="d-md-none d-lg-none"
        :showNavbar="showNavbar"
        :showCart="showCart"
        :showCartDesktop="showCartDesktop"
        :showSearch="showSearch"
        :showLogo="showLogo"
      >
      </NavbarOne>
    </custom-section>
    <custom-section class="section">
      <TabContent
          :listTab="listMenu"
          :listContent="listContent"
          class="mb-6"
          :activeTab="activeTab"
          @changeTab="(id) => (activeTab = id)"
            >
          <template v-slot:tabs_content>
            <v-row v-if="activeTab == '1'" class="mt-n6" dense>
              <v-col cols="12">
              <div
                class="card--order"
                v-for="(data, index) of historyProduct"
                :key="index"
              >
                <v-row>
                  <v-col
                    cols="12"
                    lg="3"
                    :class="{
                      'set-image-bottom': isActiveHome == false,
                      'set-image-bottom-active': isActiveHome == true,
                    }"
                    class="d-flex align-center justify-start pr-0"
                    v-if="data.from_homecare == true"
                  >
                    <img
                      src="@/assets/branch_image3.jpg"
                      height="160"
                      class="img-branch d-none d-lg-block"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="3"
                    class="d-flex align-center justify-start pr-0"
                    v-else
                  >
                    <img
                      src="@/assets/branch_image3.jpg"
                      height="160"
                      class="img-branch d-none d-lg-block"
                    />
                  </v-col>
                  <v-col cols="12" lg="9" class="delete-padding-top">
                    <div class="border-side">
                      <v-row>
                        <v-col
                          cols="7"
                          md="6"
                          class="bottom-none top-mobile-none"
                        >
                          <div class="show-btn-nav">
                            <div class="order-number">
                              {{ data.order_number }}
                            </div>
                            <span
                              :class="{
                                paid: data.status == `paid`,
                                unpaid: data.status == `unpaid`,
                                failure: data.status == `failure`,
                              }"
                              >{{ data.status }}</span
                            >
                            <span class="pl-1"
                              >IDR {{ formater(data.amount_total) }}.00</span
                            >
                            <template v-if="data.status == `unpaid`">
                              |
                              <b
                                @click="openInstructions(data)"
                                class="iconsSet"
                                >{{ $t("page.my_order.view_payment_instructions") }}</b
                              >
                            </template>
                            <template
                              v-if="
                                data.status == `paid` &&
                                data.from_homecare != true
                              "
                            >
                              |
                              <b
                                @click="openReceipt(data.order_number)"
                                class="iconsSet"
                                >View Invoice</b
                              >
                            </template>
                          </div>
                          <div class="hide-btn-nav pb-3">
                            <div class="order-number">
                              {{ data.order_number }}
                            </div>
                            <span
                              :class="{
                                paid: data.status == `paid`,
                                unpaid: data.status == `unpaid`,
                                failure: data.status == `failure`,
                              }"
                              >{{ data.status }}</span
                            >
                            <span class="pl-1"
                              >IDR {{ formater(data.amount_total) }}.00</span
                            ><br />
                            <template v-if="data.status == `unpaid`">
                              <b
                                @click="openInstructions(data)"
                                class="iconsSet"
                                >{{ $t("page.my_order.view_payment_instructions") }}</b
                              >
                            </template>
                            <template
                              v-if="
                                data.status == `paid` &&
                                data.from_homecare != true
                              "
                            >
                              <b
                                @click="openReceipt(data.order_number)"
                                class="iconsSet"
                                >View Invoice</b
                              >
                            </template>
                          </div>
                        </v-col>
                        <v-col
                          cols="5"
                          md="6"
                          class="bottom-none pr-0 d-flex justify-end top-mobile-none"
                        >
                          <div class="show-btn-nav pt-4">
                            <div class="d-flex justify-end">
                              <icons icon="pin-location" color="#1f3c87" />
                              <a
                                :href="`https://www.google.com/maps/?q=${data.plant_longlat}`"
                                target="_blank"
                                class="href-link"
                                >Direction</a
                              >
                            </div>
                          </div>
                          <div
                            class="show-btn-nav"
                            v-if="data.rating == null || data.rating == ''"
                          >
                            <div class="border-5 align-end">
                              <span
                                class="button-rating"
                                @click="editItem(data)"
                                >Give Rating</span
                              >
                            </div>
                            <div class="align-end">
                              <span class="button-irisan">Give Rating</span>
                            </div>
                            <div class="align-end">
                              <span class="button-transparent"
                                >Give Rating</span
                              >
                            </div>
                          </div>
                          <div class="show-btn-nav" v-else>
                            <div class="border-5 align-end">
                              <span class="button-rating-done"
                                >{{ data.rating }}
                                <v-icon
                                  small
                                  color="white"
                                  style="font-size: 12px !important"
                                  >mdi-star</v-icon
                                ></span
                              >
                            </div>
                            <div class="align-end">
                              <span class="button-irisan">Give Rating</span>
                            </div>
                            <div class="align-end">
                              <span class="button-transparent"
                                >Give Rating</span
                              >
                            </div>
                          </div>
                          <div
                            class="mt-1 hide-btn-nav pr-3"
                            v-if="data.rating == null || data.rating == ''"
                          >
                            <span
                              class="button-rating-sm"
                              @click="editItem(data)"
                              >Give Rating</span
                            >
                          </div>
                          <div class="mt-1 hide-btn-nav pr-3" v-else>
                            <span class="button-rating-done-sm"
                              >{{ data.rating }}
                              <v-icon
                                small
                                color="white"
                                style="font-size: 12px !important"
                                >mdi-star</v-icon
                              ></span
                            >
                          </div>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: 0px !important">
                        <v-col cols="12" md="3" class="pb-0 pt-lg-0">
                          <div class="subtitle-order">Your Appointment On</div>
                          <div v-if="data.booking_date != null">
                            {{ formatDate(data.booking_date) }} at
                            {{ data.estimated_service_at }}
                          </div>
                          <div v-else>
                            {{ formatDate(data.registration_date) }} at
                            {{ data.estimated_service_at }}
                          </div>
                        </v-col>
                        <template v-if="data.from_homecare != true">
                          <v-col cols="12" md="4" class="pb-0 pt-lg-0">
                            <div class="subtitle-order">Lab</div>
                            <div>{{ data.plant }}</div>
                          </v-col>
                          <v-col cols="12" md="3" class="pb-0 pt-lg-0">
                            <div class="subtitle-order">Booking Code</div>
                            <v-row v-if="data.booking_code != null">
                              <v-col cols="4" md="8" lg="8">
                                <div>{{ data.booking_code[0] }}</div>
                              </v-col>
                              <v-col cols="8" md="4" lg="4"
                                ><div
                                  @click="copyTextFun(data.booking_code[0])"
                                >
                                  <icons icon="icon-copy" class="iconsSet" />
                                </div>
                              </v-col>
                            </v-row>
                            <v-row v-else>
                              <v-col> <div>-</div></v-col>
                            </v-row>
                          </v-col>
                        </template>
                        <template v-else>
                          <v-col cols="12" md="8" class="pb-0 pt-lg-0">
                            <div class="subtitle-order">Location</div>
                            <div>
                              {{
                                `${data.sample_pickup_location.toUpperCase()}`
                              }}
                            </div>
                          </v-col>
                        </template>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="7" class="bottom-mobile-none">
                          <div class="subtitle-order">Type of Appointment</div>
                          <div>
                            <template
                              v-if="
                                data.type != 'BUY PACKAGE' &&
                                data.from_homecare != true
                              "
                            >
                              <span
                                v-for="(
                                  product, productIndex
                                ) of data.product_order"
                                :key="productIndex"
                                class="title-test"
                                >{{ product
                                }}{{
                                  data.product_order.length - 1 == productIndex
                                    ? "."
                                    : ", "
                                }}</span
                              >
                            </template>
                            <template
                              v-else-if="
                                data.type == 'BUY PACKAGE' &&
                                data.from_homecare != true
                              "
                            >
                              <span class="title-test">{{ data.package }}</span>
                            </template>
                            <template v-else>
                              <span>HOME CARE</span>
                            </template>
                          </div>
                        </v-col>
                        <template v-if="data.from_homecare != true">
                          <v-col cols="12" md="5">
                            <div class="hide-btn-nav pb-3">
                              <div class="d-flex justify-start">
                                <icons icon="pin-location" color="#1f3c87" />
                                <a
                                  :href="`https://www.google.com/maps/?q=${data.plant_longlat}`"
                                  target="_blank"
                                  class="href-link"
                                  >Direction</a
                                >
                              </div>
                            </div>
                            <v-row
                              class="button-action-set"
                              v-if="data.approved != true"
                            >
                              <v-col cols="6" class="mt-md-5">
                                <v-btn
                                  v-if="data.type != 'BUY PACKAGE'"
                                  block
                                  class="button-qr"
                                  @click="showQr(data)"
                                >
                                  View QR
                                </v-btn>
                                <v-btn
                                  v-else
                                  block
                                  class="button-qr"
                                  @click="showQrPackage(data)"
                                >
                                  View QR
                                </v-btn>
                              </v-col>
                              <v-col
                                cols="6"
                                class="mt-md-5 delete-padding-left pr-md-0"
                              >
                                <v-btn
                                  block
                                  class="button-actions"
                                  @click="showDateReschedule(data)"
                                >
                                  Reschedule
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-row class="button-action-set" v-else>
                              <v-col
                                cols="6"
                                class="top-40 show-btn-nav"
                              ></v-col>
                              <v-col
                                cols="6"
                                class="top-40 show-btn-nav delete-padding-left pr-md-0"
                              >
                                <v-btn
                                  v-if="data.type != 'BUY PACKAGE'"
                                  block
                                  class="button-actions"
                                  @click="openDialogOrder(data)"
                                >
                                  View Result
                                </v-btn>
                                <v-btn
                                  v-else
                                  block
                                  class="button-actions"
                                  @click="openDialogOrderPackage(data)"
                                >
                                  View Result
                                </v-btn>
                              </v-col>
                              <v-col cols="12" class="hide-btn-nav">
                                <v-btn
                                  v-if="data.type != 'BUY PACKAGE'"
                                  block
                                  class="button-actions"
                                  @click="openDialogOrder(data)"
                                >
                                  View Result
                                </v-btn>
                                <v-btn
                                  v-else
                                  block
                                  class="button-actions"
                                  @click="openDialogOrderPackage(data)"
                                >
                                  View Result
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </template>
                        <template v-else>
                          <v-col cols="12" md="5">
                            <v-row class="button-action-set">
                              <v-col
                                cols="6"
                                class="top-40 show-btn-nav"
                              ></v-col>
                              <v-col
                                cols="6"
                                class="top-40 show-btn-nav delete-padding-left pr-md-0 pb-0"
                              >
                                <v-btn
                                  block
                                  class="button-qr"
                                  @click="showHomeCare(data)"
                                  v-if="checkItem != data.order_number"
                                >
                                  View Participant
                                </v-btn>
                                <v-btn
                                  block
                                  class="button-qr"
                                  @click="closeHomeCare()"
                                  v-if="checkItem == data.order_number"
                                >
                                  Close
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-row class="button-action-set">
                              <v-col cols="12" class="hide-btn-nav">
                                <v-btn
                                  block
                                  class="button-qr"
                                  @click="showHomeCare(data)"
                                  v-if="checkItem != data.order_number"
                                >
                                  View Participant
                                </v-btn>
                                <v-btn
                                  block
                                  class="button-qr"
                                  @click="closeHomeCare()"
                                  v-if="checkItem == data.order_number"
                                >
                                  Close
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </template>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>

                <v-row
                  v-if="
                    data.from_homecare == true && checkItem == data.order_number
                  "
                >
                  <v-col cols="12" class="pt-0">
                    <div class="table-responsive">
                      <v-data-table
                        :headers="headerTable"
                        :items="orderHomecareDetail"
                        hide-default-footer
                        :mobile-breakpoint="0"
                        class="elevation-1 table-homecare"
                      >
                        <template v-slot:item="{ item }">
                          <tr>
                            <td valign="top" class="pt-2">
                              {{ item.patient_id_number }}
                            </td>
                            <td valign="top" class="pt-2">
                              {{ item.patient }}
                            </td>
                            <td valign="top" class="pt-2">
                              {{ item.booking_code }}
                            </td>
                            <td valign="top" class="pt-2">
                              <span
                                v-for="(
                                  product, productIndex
                                ) of item.product_order"
                                :key="productIndex"
                              >
                                {{ product }}
                                {{
                                  item.product_order.length - 1 == productIndex
                                    ? "."
                                    : " | "
                                }}
                              </span>
                            </td>
                            <td>
                              <template v-if="item.status == `paid`">
                                <b
                                  @click="openReceipt(item.order_number)"
                                  class="iconsSet"
                                  >View Invoice</b
                                >
                                |
                              </template>
                              <!-- <template v-if="data.approved != true">

                              </template> -->
                              <b
                                @click="showQrHomecare(item)"
                                v-if="data.approved != true"
                                class="iconsSet"
                                >View QR</b
                              >
                              <b
                                @click="openDialogOrder(data)"
                                v-if="data.approved == true"
                                class="iconsSet"
                                >View Result</b
                              >
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                  </v-col>
                </v-row>
              </div>
              </v-col>
            </v-row>
            <v-row v-if="activeTab == '2'" class="mt-n6" dense>
              <v-col cols="12">
              <div
                class="card--order"
                v-for="(data, index) of historyPackage"
                :key="index"
              >
                <v-row>
                  <v-col
                    cols="12"
                    lg="3"
                    :class="{
                      'set-image-bottom': isActiveHome == false,
                      'set-image-bottom-active': isActiveHome == true,
                    }"
                    class="d-flex align-center justify-start pr-0"
                    v-if="data.from_homecare == true"
                  >
                    <img
                      src="@/assets/branch_image3.jpg"
                      height="160"
                      class="img-branch d-none d-lg-block"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="3"
                    class="d-flex align-center justify-start pr-0"
                    v-else
                  >
                    <img
                      src="@/assets/branch_image3.jpg"
                      height="160"
                      class="img-branch d-none d-lg-block"
                    />
                  </v-col>
                  <v-col cols="12" lg="9" class="delete-padding-top">
                    <div class="border-side">
                      <v-row>
                        <v-col
                          cols="7"
                          md="6"
                          class="bottom-none top-mobile-none"
                        >
                          <div class="show-btn-nav">
                            <div class="order-number">
                              {{ data.order_number }}
                            </div>
                            <span
                              :class="{
                                paid: data.status == `paid`,
                                unpaid: data.status == `unpaid`,
                                failure: data.status == `failure`,
                              }"
                              >{{ data.status }}</span
                            >
                            <span class="pl-1"
                              >IDR {{ formater(data.amount_total) }}.00</span
                            >
                            <template v-if="data.status == `unpaid`">
                              |
                              <b
                                @click="openInstructions(data)"
                                class="iconsSet"
                                >{{ $t("page.my_order.view_payment_instructions") }}</b
                              >
                            </template>
                            <template
                              v-if="
                                data.status == `paid` &&
                                data.from_homecare != true
                              "
                            >
                              |
                              <b
                                @click="openReceipt(data.order_number)"
                                class="iconsSet"
                                >View Invoice</b
                              >
                            </template>
                          </div>
                          <div class="hide-btn-nav pb-3">
                            <div class="order-number">
                              {{ data.order_number }}
                            </div>
                            <span
                              :class="{
                                paid: data.status == `paid`,
                                unpaid: data.status == `unpaid`,
                                failure: data.status == `failure`,
                              }"
                              >{{ data.status }}</span
                            >
                            <span class="pl-1"
                              >IDR {{ formater(data.amount_total) }}.00</span
                            ><br />
                            <template v-if="data.status == `unpaid`">
                              <b
                                @click="openInstructions(data)"
                                class="iconsSet"
                                >{{ $t("page.my_order.view_payment_instructions") }}</b
                              >
                            </template>
                            <template
                              v-if="
                                data.status == `paid` &&
                                data.from_homecare != true
                              "
                            >
                              <b
                                @click="openReceipt(data.order_number)"
                                class="iconsSet"
                                >View Invoice</b
                              >
                            </template>
                          </div>
                        </v-col>
                        <v-col
                          cols="5"
                          md="6"
                          class="bottom-none pr-0 d-flex justify-end top-mobile-none"
                        >
                          <div class="show-btn-nav pt-4">
                            <div class="d-flex justify-end">
                              <icons icon="pin-location" color="#1f3c87" />
                              <a
                                :href="`https://www.google.com/maps/?q=${data.plant_longlat}`"
                                target="_blank"
                                class="href-link"
                                >Direction</a
                              >
                            </div>
                          </div>
                          <div
                            class="show-btn-nav"
                            v-if="data.rating == null || data.rating == ''"
                          >
                            <div class="border-5 align-end">
                              <span
                                class="button-rating"
                                @click="editItemPackage(data)"
                                >Give Rating</span
                              >
                            </div>
                            <div class="align-end">
                              <span class="button-irisan">Give Rating</span>
                            </div>
                            <div class="align-end">
                              <span class="button-transparent"
                                >Give Rating</span
                              >
                            </div>
                          </div>
                          <div class="show-btn-nav" v-else>
                            <div class="border-5 align-end">
                              <span class="button-rating-done"
                                >{{ data.rating }}
                                <v-icon
                                  small
                                  color="white"
                                  style="font-size: 12px !important"
                                  >mdi-star</v-icon
                                ></span
                              >
                            </div>
                            <div class="align-end">
                              <span class="button-irisan">Give Rating</span>
                            </div>
                            <div class="align-end">
                              <span class="button-transparent"
                                >Give Rating</span
                              >
                            </div>
                          </div>
                          <div
                            class="mt-1 hide-btn-nav pr-3"
                            v-if="data.rating == null || data.rating == ''"
                          >
                            <span
                              class="button-rating-sm"
                              @click="editItemPackage(data)"
                              >Give Rating</span
                            >
                          </div>
                          <div class="mt-1 hide-btn-nav pr-3" v-else>
                            <span class="button-rating-done-sm"
                              >{{ data.rating }}
                              <v-icon
                                small
                                color="white"
                                style="font-size: 12px !important"
                                >mdi-star</v-icon
                              ></span
                            >
                          </div>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: 0px !important">
                        <v-col cols="12" md="3" class="pb-0 pt-lg-0">
                          <div class="subtitle-order">Your Appointment On</div>
                          <div v-if="data.booking_date != null">
                            {{ formatDate(data.booking_date) }} at
                            {{ data.estimated_service_at }}
                          </div>
                          <div v-else>
                            {{ formatDate(data.registration_date) }} at
                            {{ data.estimated_service_at }}
                          </div>
                        </v-col>
                        <template v-if="data.from_homecare != true">
                          <v-col cols="12" md="4" class="pb-0 pt-lg-0">
                            <div class="subtitle-order">Lab</div>
                            <div>{{ data.plant }}</div>
                          </v-col>
                          <v-col cols="12" md="3" class="pb-0 pt-lg-0">
                            <div class="subtitle-order">Booking Code</div>
                            <v-row v-if="data.booking_code != null">
                              <v-col cols="4" md="8" lg="8">
                                <div>{{ data.booking_code[0] }}</div>
                              </v-col>
                              <v-col cols="8" md="4" lg="4"
                                ><div
                                  @click="copyTextFun(data.booking_code[0])"
                                >
                                  <icons icon="icon-copy" class="iconsSet" />
                                </div>
                              </v-col>
                            </v-row>
                            <v-row v-else>
                              <v-col> <div>-</div></v-col>
                            </v-row>
                          </v-col>
                        </template>
                        <template v-else>
                          <v-col cols="12" md="8" class="pb-0 pt-lg-0">
                            <div class="subtitle-order">Location</div>
                            <div>
                              {{
                                `${data.sample_pickup_location.toUpperCase()}`
                              }}
                            </div>
                          </v-col>
                        </template>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="7" class="bottom-mobile-none">
                          <div class="subtitle-order">Type of Appointment</div>
                          <div>
                            <template
                              v-if="
                                data.type != 'BUY PACKAGE' &&
                                data.from_homecare != true
                              "
                            >
                              <span
                                v-for="(
                                  product, productIndex
                                ) of data.product_order"
                                :key="productIndex"
                                class="title-test"
                                >{{ product
                                }}{{
                                  data.product_order.length - 1 == productIndex
                                    ? "."
                                    : ", "
                                }}</span
                              >
                            </template>
                            <template
                              v-else-if="
                                data.type == 'BUY PACKAGE' &&
                                data.from_homecare != true
                              "
                            >
                              <span class="title-test">{{ data.package }}</span>
                            </template>
                            <template v-else>
                              <span>HOME CARE</span>
                            </template>
                          </div>
                        </v-col>
                        <template v-if="data.from_homecare != true">
                          <v-col cols="12" md="5">
                            <!-- <div class="show-btn-nav pb-3">
                                <div class="d-flex justify-end">
                          <icons icon="pin-location" color="#1f3c87" />
                          <a
                            :href="`https://www.google.com/maps/?q=${data.plant_longlat}`"
                            target="_blank"
                            class="href-link"
                            >Navigate Here</a
                          >
                    </div>
                              </div> -->
                            <div class="hide-btn-nav pb-3">
                              <div class="d-flex justify-start">
                                <icons icon="pin-location" color="#1f3c87" />
                                <a
                                  :href="`https://www.google.com/maps/?q=${data.plant_longlat}`"
                                  target="_blank"
                                  class="href-link"
                                  >Direction</a
                                >
                              </div>
                            </div>
                            <v-row
                              class="button-action-set"
                              v-if="data.approved != true"
                            >
                              <v-col cols="6" class="mt-md-5">
                                <v-btn
                                  v-if="data.type != 'BUY PACKAGE'"
                                  block
                                  class="button-qr"
                                  @click="showQr(data)"
                                >
                                  View QR
                                </v-btn>
                                <v-btn
                                  v-else
                                  block
                                  class="button-qr"
                                  @click="showQrPackage(data)"
                                >
                                  View QR
                                </v-btn>
                              </v-col>
                              <v-col
                                cols="6"
                                class="mt-md-5 delete-padding-left pr-md-0"
                              >
                                <v-btn
                                  block
                                  class="button-actions"
                                  @click="showDateReschedule(data)"
                                >
                                  Reschedule
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-row class="button-action-set" v-else>
                              <v-col cols="6" class="show-btn-nav"></v-col>
                              <v-col
                                cols="6"
                                class="mt-5 show-btn-nav delete-padding-left pr-md-0"
                              >
                                <v-btn
                                  v-if="data.type != 'BUY PACKAGE'"
                                  block
                                  class="button-actions"
                                  @click="openDialogOrder(data)"
                                >
                                  View Result
                                </v-btn>
                                <v-btn
                                  v-else
                                  block
                                  class="button-actions"
                                  @click="openDialogOrderPackage(data)"
                                >
                                  View Result
                                </v-btn>
                              </v-col>
                              <v-col cols="12" class="hide-btn-nav">
                                <v-btn
                                  v-if="data.type != 'BUY PACKAGE'"
                                  block
                                  class="button-actions"
                                  @click="openDialogOrder(data)"
                                >
                                  View Result
                                </v-btn>
                                <v-btn
                                  v-else
                                  block
                                  class="button-actions"
                                  @click="openDialogOrderPackage(data)"
                                >
                                  View Result
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </template>
                        <template v-else>
                          <v-col cols="12" md="5">
                            <v-row class="button-action-set">
                              <v-col
                                cols="6"
                                class="top-40 show-btn-nav"
                              ></v-col>
                              <v-col
                                cols="6"
                                class="top-40 show-btn-nav delete-padding-left pr-md-0 pb-0"
                              >
                                <v-btn
                                  block
                                  class="button-qr"
                                  @click="showHomeCare(data)"
                                  v-if="checkItem != data.order_number"
                                >
                                  View Participant
                                </v-btn>
                                <v-btn
                                  block
                                  class="button-qr"
                                  @click="closeHomeCare()"
                                  v-if="checkItem == data.order_number"
                                >
                                  Close
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-row class="button-action-set">
                              <v-col cols="12" class="hide-btn-nav">
                                <v-btn
                                  block
                                  class="button-qr"
                                  @click="showHomeCare(data)"
                                  v-if="checkItem != data.order_number"
                                >
                                  View Participant
                                </v-btn>
                                <v-btn
                                  block
                                  class="button-qr"
                                  @click="closeHomeCare()"
                                  v-if="checkItem == data.order_number"
                                >
                                  Close
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </template>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>

                <v-row
                  v-if="
                    data.from_homecare == true && checkItem == data.order_number
                  "
                >
                  <v-col cols="12" class="pt-0">
                    <div class="table-responsive">
                      <v-data-table
                        :headers="headerTable"
                        :items="orderHomecareDetail"
                        hide-default-footer
                        :mobile-breakpoint="0"
                        class="elevation-1 table-homecare"
                      >
                        <template v-slot:item="{ item }">
                          <tr>
                            <td valign="top" class="pt-2">
                              {{ item.patient_id_number }}
                            </td>
                            <td valign="top" class="pt-2">
                              {{ item.patient }}
                            </td>
                            <td valign="top" class="pt-2">
                              {{ item.booking_code }}
                            </td>
                            <td valign="top" class="pt-2">
                              <span
                                v-for="(
                                  product, productIndex
                                ) of item.product_order"
                                :key="productIndex"
                              >
                                {{ product }}
                                {{
                                  item.product_order.length - 1 == productIndex
                                    ? "."
                                    : " | "
                                }}
                              </span>
                            </td>
                            <td>
                              <template v-if="item.status == `paid`">
                                <b
                                  @click="openReceipt(item.order_number)"
                                  class="iconsSet"
                                  >View Invoice</b
                                >
                                |
                              </template>
                              <!-- <template v-if="data.approved != true">

                              </template> -->
                              <b
                                @click="showQrHomecare(item)"
                                v-if="data.approved != true"
                                class="iconsSet"
                                >View QR</b
                              >
                              <b
                                @click="openDialogOrder(data)"
                                v-if="data.approved == true"
                                class="iconsSet"
                                >View Result</b
                              >
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                  </v-col>
                </v-row>
              </div>
              </v-col>
            </v-row>
          </template>
      </TabContent>
          </custom-section>


    <v-dialog v-model="dialogQrPackage" width="500" class="bg-blue">
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="9" style="padding-bottom: 0px"></v-col>
            <v-col
              cols="3"
              class="d-flex align-center justify-end"
              style="padding-bottom: 0px"
            >
              <v-btn @click="dialogQrPackage = false" class="btn-cancel">
                <IconCancel />
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-card-text>
          <v-col
            cols="12"
            class="d-flex align-center justify-center"
            style="padding-top: 5px; padding-bottom: 22px"
          >
            <qrcode-vue
              :value="qrCodeSetPackage"
              :size="sizes"
              level="H"
            ></qrcode-vue>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- date reschedule -->
    <v-dialog v-model="menu" :close-on-content-click="false" max-width="290">
      <v-date-picker
      :locale="$i18n.locale"
        header-color="#00A4B3"
        v-model="payload.date"
        @change="updDate(registNo)"
        @input="menu = false"
        :min="
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        "
      ></v-date-picker>
    </v-dialog>

    <!-- qrcode -->
    <v-dialog v-model="dialog" width="500" class="bg-blue">
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="9" style="padding-bottom: 0px"></v-col>
            <v-col
              cols="3"
              class="d-flex align-center justify-end"
              style="padding-bottom: 0px"
            >
              <v-btn @click="dialog = false" class="btn-cancel">
                <IconCancel />
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-card-text>
          <v-col
            cols="12"
            class="d-flex align-center justify-center"
            style="padding-top: 5px; padding-bottom: 22px"
          >
            <qrcode-vue :value="qrCodeSet" :size="sizes" level="H"></qrcode-vue>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- rating modal popup -->
    <v-dialog v-model="dialogEdit" max-width="500px">
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-title>Give Rating</v-card-title>
          <v-divider class="mt-1"></v-divider>
          <v-card-text style="padding-bottom: 3px !important">
            <p class="text-center">
              How was the service?<br />(1 is disappointing, 5 is awesome)
            </p>
            <div class="text-center mt-3 mb-4">
              <v-rating
                v-model="editedItem.rating"
                color="yellow darken-3"
                background-color="grey darken-1"
                empty-icon="$ratingFull"
                hover
                large
              ></v-rating>
            </div>
            <v-textarea
              ref="comment"
              v-model="editedItem.comment"
              :rules="[() => !!editedItem.comment || 'This field is required']"
              :error-messages="errorMessages"
              solo
              name="comment"
              :label="$t('write_review')"
              row-height="5"
            ></v-textarea>
          </v-card-text>
          <v-divider class="mt-1"></v-divider>
          <v-card-actions class="justify-end">
            <v-btn text @click="closeEdit"> Cancel </v-btn>
            <v-btn color="#00a4b3" text @click="save"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- rating modal popup Package-->
    <v-dialog v-model="dialogEditPackage" max-width="500px">
      <v-form ref="formPackage" lazy-validation>
        <v-card>
          <v-card-title>Give Rating</v-card-title>
          <v-divider class="mt-1"></v-divider>
          <v-card-text style="padding-bottom: 3px !important">
            <p class="text-center">
              How was the service?<br />(1 is disappointing, 5 is awesome)
            </p>
            <div class="text-center mt-3 mb-4">
              <v-rating
                v-model="editedItemPackage.rating"
                color="yellow darken-3"
                background-color="grey darken-1"
                empty-icon="$ratingFull"
                hover
                large
              ></v-rating>
            </div>
            <v-textarea
              ref="comment"
              v-model="editedItemPackage.comment"
              :rules="[
                () => !!editedItemPackage.comment || 'This field is required',
              ]"
              :error-messages="errorMessages"
              solo
              name="comment"
              :label="$t('write_review')"
              row-height="5"
            ></v-textarea>
          </v-card-text>
          <v-divider class="mt-1"></v-divider>
          <v-card-actions class="justify-end">
            <v-btn text @click="closeEditPackage"> Cancel </v-btn>
            <v-btn color="#00a4b3" text @click="savePackage"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- start modal popup order detail (dialog_order)-->
    <DialogOrderDetail
      v-if="dialog_order"
      :item="dataDialogOrder"
      :tampilkan="dialog_order"
      @toggleDialog="(is_show) => toggleDialogOrder(is_show)"
    />

    <!-- start modal popup order detail (dialog_order mcu)-->
    <DialogOrderDetailMCU
      :item="dataDialogOrderMCU"
      :tampilkan="dialog_order_mcu"
      :tableFilter="tableHomeCare"
      @toggleDialog="(is_show) => toggleDialogOrderMCU(is_show)"
      v-if="dialog_order_mcu"
    />
  </div>
</template>

<script>
import DialogOrderDetail from "@/components/v2/dialog/dialogOrderDetail.vue";
import DialogOrderDetailMCU from "@/components/v2/dialog/dialogOrderDetailMCU.vue";
import TextField from "@/components/v2/input/text_field.vue";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
import CustomSection from "@/components/v2/section/index.vue";
import moment from "moment";
import icons from "@/components/elements/Icons.vue";
import QrcodeVue from "qrcode.vue";
import IconCancel from "@/components/elements/IconCancel.vue";

import UserCard from "@/components/v2/sidebar/UserCard.vue";
import DialogMenu from "@/components/v2/dialog/dialogMenu.vue";
import notification from "@/components/v2/notification/index.vue";
import Cart from "@/components/headers/Cart.vue";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
import TabContent from "@/components/v2/tabs/index.vue";

export default {
  name: "Home",
  components: {
    TextField,
    CustomSection,
    icons,
    QrcodeVue,
    IconCancel,
    DialogOrderDetailMCU,
    DialogOrderDetail,
    UserCard,
    DialogMenu,
    notification,
    Cart,
    NavbarOne,
    TabContent,
  },
  data: () => ({
    listMenu: [
      {
        id: 1,
        title: 'Ongoing Test'
      },
      {
        id: 2,
        title: 'Your History'
      },
    ],
    listContent: [
      {
        id: 1,
        title: 'Ongoing Test'
      },
      {
        id: 2,
        title: 'Your History'
      },
    ],
    activeTab: 1,
    showNavbar: true,
    showCart: true,
    showCartDesktop: true,
    showSearch: true,
    showLogo: false,
    headerTable: [
      {
        text: "ID",
        value: "patient_id_number",
        sortable: false,
        width: "50px",
      },
      {
        text: "Patient Name",
        value: "patient",
        sortable: false,
        width: "150px",
      },
      {
        text: "Booking Code",
        value: "booking_code",
        sortable: false,
        width: "95px",
      },
      { text: "Test", value: "product_order", sortable: false, width: "350px" },
      { text: "Actions", value: "actions", sortable: false, width: "150px" },
    ],

    payload: {
      registration_number: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    },
    menu: false,
    registNo: "",
    panel: [0],
    historyProduct: [],
    historyPackage: [],
    sizes: 300,
    qrCodeSet: "",
    qrCodeSetPackage: "",
    dialogQrPackage: false,
    dialog: false,
    dialog_order: false,
    dialog_order_mcu: false,
    checkItem: "",
    dataDialogOrder: {
      order_id: null,
      list: [
        {
          document_information: {
            number_of_report: null,
            medical_record_number: null,
            datetime_observation: null,
            gender: null,
            plant: null,
            primary_doctor: null,
            lab_chief: null,
          },
          customers_information: {
            patient_name: null,
            address: null,
            city: null,
            province: null,
            postal_code: null,
            country: null,
            dob: null,
            patient_age: null,
          },
          product: {
            product_id: null,
            product_code: null,
            product_name: null,
          },
        },
      ],
    },
    dataDialogOrderMCU: {
      order_id: null,
      list: [],
    },
    groupItemCategory: [],
    groupItemSubcategory: [],
    tableHomeCare: [],
    orderHomecareDetail: [],
    download: {},
    preview: {},
    rating: 0,
    dialogEdit: false,
    editedIndex: -1,
    dialogEditPackage: false,
    editedIndexPackage: -1,
    isActiveHome: false,
    editedItem: {
      comment: "",
      rating: 0,
    },
    defaultItem: {
      comment: "",
      rating: 0,
    },
    editedItemPackage: {
      comment: "",
      rating: 0,
    },
    defaultItemPackage: {
      comment: "",
      rating: 0,
    },
    errorMessages: "",
    dialogMenu: false,
  }),
  watch: {
    dialogEdit(val) {
      val || this.closeEdit();
    },
    dialogEditPackage(val) {
      val || this.closeEditPackage();
    },
  },
  async mounted() {
    this.$store.commit("setLoading", true);
    // this.checkItem = false;
    await API.get(
      `${process.env.VUE_APP_API_TRUST}registration/get-order-list`,
      {
        Authorization: `Bearer ${Auth.getToken()}`,
      }
    ).then((res) => {
      if (res.statusCode === 200) {
        const filterProduct = res.results.filter((dataByProduct) => {
          return dataByProduct.approved == false;
        });
        const filterPackage = res.results.filter((dataByPackage) => {
          return dataByPackage.approved == true;
        });
        const testProduct = filterProduct.filter((dataProduct) => {
          return dataProduct.status != "failure";
        });
        const testPackage = filterPackage.filter((dataPackage) => {
          return dataPackage.status != "failure";
        });
        this.historyProduct = testProduct;
        this.historyPackage = testPackage;
      }
    });

    this.$store.commit("setLoading", false);
  },
  methods: {
    toggleDialogOrder(toggle) {
      this.dialog_order = toggle;
    },
    toggleDialogOrderMCU(toggle) {
      this.dialog_order_mcu = toggle;
    },
    editItem(item) {
      this.editedIndex = this.historyProduct.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },
    editItemPackage(itemPackage) {
      this.editedIndexPackage = this.historyPackage.indexOf(itemPackage);
      this.editedItemPackage = Object.assign({}, itemPackage);
      this.dialogEditPackage = true;
    },
    closeEdit() {
      this.errorMessages = [];
      this.formHasErrors = false;
      this.dialogEdit = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeEditPackage() {
      this.errorMessages = [];
      this.formHasErrors = false;
      this.dialogEditPackage = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();

      this.$nextTick(() => {
        this.editedItemPackage = Object.assign({}, this.defaultItemPackage);
        this.editedIndexPackage = -1;
      });
    },
    copyTextFun(data) {
      navigator.clipboard.writeText(data);
    },
    formater(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatDate(par) {
      if (!par) return null;
      return moment(par).format("DD-MMM-YYYY").toUpperCase();
    },

    openInstructions(history) {
      if (this.$store.state.midtransIsLoaded) {
        const vuethis = this;
        window.snap.pay(history.midtrans_token, {
          onSuccess: function (result) {
            
            vuethis.midtransStatus = result.statusCode;
            vuethis.$router.push(`/${this.$i18n.locale}/payment-success`);
          },
          onPending: function (result) {
            vuethis.midtransStatus = result.statusCode;
            API.get(
              `${process.env.VUE_APP_API_TRUST}registration/status-transaction?invoice_number=${history.invoice_number[0]}`,
              {
                Authorization: `Bearer ${Auth.getToken()}`,
              }
            ).then((res) => {
              if (res.status_code == 200) {
              
                vuethis.$router.push(`/${this.$i18n.locale}/payment-success`);
              }
            });
          },
          onError: function (result) {
            vuethis.midtransStatus = result.statusCode;
            vuethis.$swal({
              icon: "error",
              text: "Error payment!",
            });
          },
        });
      }
    },
    async openDialogOrder(item) {
      this.dataDialogOrder.order_id = item.order_id;
      this.dataDialogOrder.type_test = item.type;
      this.getOrderDetailPopup();
      this.dialog_order = true;
    },
    async openDialogOrderPackage(item) {
      this.dataDialogOrderMCU.order_id = item.order_id;
      this.dataDialogOrderMCU.type_test = item.type;
      this.getOrderDetailPopupMCU();
      this.dialog_order_mcu = true;
    },
    async getOrderDetailPopup() {
      let order_id = this.dataDialogOrder.order_id;

      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}lab-results/get-detail?order_id=` +
            order_id +
            ``,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.dataDialogOrder.list = res.results;
      } catch (error) {
        console.log(error);
      }
    },
    async getOrderDetailPopupMCU() {
      let order_id = this.dataDialogOrderMCU.order_id;

      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/mcu-record/get-all-mcu?order_id=` +
            order_id +
            ``,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        let groupCategory =
          res.results[0].dataDiagnostic.diagnostic.diagnostic_detail.reduce(
            (r, a) => {
              r[a.sub_category] = [...(r[a.sub_category] || []), a];
              return r;
            },
            {}
          );

        this.groupItemCategory = Object.entries(groupCategory).map((arr) => ({
          nameCategory: arr[0],
          dataCategory: arr[1],
        }));

        let filterItem = [];
        this.groupItemCategory.forEach((element) => {
          filterItem = [
            ...filterItem,
            { text: element.nameCategory, type: "category" },
          ];
          let groupSubcategory = element.dataCategory.reduce((r, a) => {
            r[a.product_name] = [...(r[a.product_name] || []), a];
            return r;
          }, {});
          this.groupItemSubategory = Object.entries(groupSubcategory).map(
            (arr) => ({
              nameSubcategory: arr[0],
              dataSubcategory: arr[1],
            })
          );

          this.groupItemSubategory.forEach((element) => {
            filterItem = [
              ...filterItem,
              { text: element.nameSubcategory, type: "subcategory" },
            ];
            element.dataSubcategory.forEach((element) => {
              filterItem = [...filterItem, { ...element, type: "item" }];
            });
          });
        });

        this.dataDialogOrderMCU.list = res.results;
        this.tableHomeCare = filterItem;
      } catch (error) {
        console.log(error);
      }
    },
    openReceipt(item) {
      API.get(
        `${process.env.VUE_APP_API_TRUST}invoices/get-printout?order_number=${item}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then((res) => {
        this.preview = res;

        window.open(this.preview.linkDownload, "_blank");
      });
    },
    downloadLab(param) {
      API.get(
        `${process.env.VUE_APP_API_TRUST}diagnosis/get-labreport?order_number=${param}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then((res) => {
        this.download = res;
        window.open(this.download.linkDownload, "_blank");
      });
    },
    downloadMcu(param) {
      API.get(
        `${process.env.VUE_APP_API_TRUST}diagnosis/get-mcureport?order_number=${param}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then((res) => {
        this.download = res;
        window.open(this.download.linkDownload, "_blank");
      });
    },
    savePackage() {
      if (this.$refs.formPackage.validate() == true) {
        // reference instance ke variabel karena tidak akan terbaca pada proses async
        var vm = this;

        // Jika ada edit item, karena jika diedit index akan selalu lebih dari -1
        if (this.editedIndexPackage > -1) {
          try {
            let form = {
              order_number:
                typeof this.editedItemPackage.order_number !== "undefined"
                  ? this.editedItemPackage.order_number
                  : "",
              rating:
                typeof this.editedItemPackage.rating !== "undefined"
                  ? this.editedItemPackage.rating
                  : "",
              comment:
                typeof this.editedItemPackage.comment !== "undefined"
                  ? this.editedItemPackage.comment.toString().toUpperCase()
                  : "",
            };
            // Begin api request
            (async function () {
              const res = await API.post(
                `${process.env.VUE_APP_API_TRUST}invoices/save-rating`,
                // ?lang=${vm.$i18n.locale}
                {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${Auth.getToken()}`,
                },
                [form]
              );

              if (res.statusCode === 200) {
                vm.toast("success", vm.$t("success_insert_data"));
                setTimeout(function () {
                  location.reload();
                }, 200);
              } else if (res.statusCode == 403) {
                //vm.toast("error", res.message);
                Object.keys(res.errors).forEach((element) => {
                  vm.toast(
                    "error",
                    typeof res.errors[element] == "object"
                      ? res.errors[element][0]
                      : res.errors[element]
                  );
                });
              } else {
                vm.$swal({
                  icon: "error",
                  text: res.message,
                  //text: vm.$t("error_try_again_latter"),
                });
              }
            })();
          } catch (error) {
            this.$swal({
              icon: "error",
              text: vm.$t("error_try_again_latter"),
            });
          }
          this.closeEditPackage();
        }
      }
    },
    save() {
      if (this.$refs.form.validate() == true) {
        // reference instance ke variabel karena tidak akan terbaca pada proses async

        var vm = this;
        // Jika ada edit item, karena jika diedit index akan selalu lebih dari -1
        if (this.editedIndex > -1) {
          try {
            let form = {
              order_number:
                typeof this.editedItem.order_number !== "undefined"
                  ? this.editedItem.order_number
                  : "",
              rating:
                typeof this.editedItem.rating !== "undefined"
                  ? this.editedItem.rating
                  : "",
              comment:
                typeof this.editedItem.comment !== "undefined"
                  ? this.editedItem.comment.toString().toUpperCase()
                  : "",
            };

            // Begin api request
            (async function () {
              const res = await API.post(
                `${process.env.VUE_APP_API_TRUST}invoices/save-rating`,
                // ?lang=${vm.$i18n.locale}
                {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${Auth.getToken()}`,
                },
                [form]
              );

              if (res.statusCode === 200) {
                vm.toast("success", vm.$t("success_insert_data"));
                setTimeout(function () {
                  location.reload();
                }, 200);
              } else if (res.statusCode == 403) {
               // vm.toast("error", res.message);
                Object.keys(res.errors).forEach((element) => {
                  vm.toast(
                    "error",
                    typeof res.errors[element] == "object"
                      ? res.errors[element][0]
                      : res.errors[element]
                  );
                });
              } else {
                vm.$swal({
                  icon: "error",
                  text: res.message,
                  //text: vm.$t("error_try_again_latter"),
                });
              }
            })();
          } catch (error) {
            this.$swal({
              icon: "error",
              text: vm.$t("error_try_again_latter"),
            });
          }
          this.closeEdit();
        }
      }
    },
    showQr(data) {
      this.dialog = true;
      this.qrCodeSet = data.booking_code[0];
    },
    showQrHomecare(data) {
      this.dialog = true;
      this.qrCodeSet = data.booking_code;
    },
    showQrPackage(data) {
      this.dialogQrPackage = true;
      this.qrCodeSetPackage = data.booking_code[0];
    },
    showDateReschedule(data) {
      this.menu = true;
      this.registNo = data.registration_number[0];
      // this.qrCodeSetPackage = data.booking_code[0];
    },
    async updDate(data) {
      const payload = {
        registration_number: data,
        date: this.payload.date,
      };

      try {
        const resp = await API.post(
          `${process.env.VUE_APP_API_TRUST}registration/reschedule`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          payload
        );
        if (resp.statusCode == 200) {
          this.toast("success", "Reschedule success");
          setTimeout(function () {
            location.reload();
          }, 200);
        } else {
          this.$swal({
            icon: "error",
            text: resp.errors.message,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    async showHomeCare(data) {
    
      try {
        await API.get(
          `${process.env.VUE_APP_API_TRUST}registration/get-order-detail?registration_number=${data.parent_registration_number}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then((res) => {
          if (res.statusCode === 200) {
            this.orderHomecareDetail = res.results.orders;
          }
        });
      } catch (error) {
        console.log(error);
      }
      this.checkItem = data.order_number;
      this.isActiveHome = true;
    },
    closeHomeCare() {
      this.isActiveHome = false;
      this.checkItem = "";
    },

    async openDialog() {
      this.dialog = true;
    },
    toggleDialogMenu(toggle) {
      this.dialog = toggle;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.home {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
  &__section {
    padding: 0 20px;
  }
}

a {
  text-decoration: none;
}

.v-application p {
  margin-bottom: 9px;
}

.text-field {
  max-width: 70%;
  margin-top: 6px;
  margin-left: 15px;
}

// stylefilterTab
.header {
  margin-bottom: 11px;
  margin-top: 2px;
}

.tagline {
  font-size: 20px;
  font-weight: bold;
  color: #1f3c87;
}

.theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px !important;
}
.v-label {
  font-size: 12px;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  min-width: 130px;
  padding: 0 16px;
}

// endstylefilterTab
.card--order {
  border-radius: 6px;
  background: #fff;
  margin-top: 20px;
  border: 1px solid #e0e0e0;
  font-size: 12px;
}
.img-branch {
  border-radius: 6px 0 0 6px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.order-number {
  font-size: 16px;
  font-weight: bold;
  color: #00a4b3;
  padding-top: 8px !important;
}
.subtitle-order {
  color: #1f3c87;
  font-weight: 700;
}
.button-actions {
  background-color: #00a4b3 !important;
  border-radius: 4px;
  color: white !important;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  //   text-transform: capitalize;
  // height: 30px !important;
  // min-width: 105px !important;
}
.button-qr {
  border-radius: 4px;
  color: #33344f !important;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  //   text-transform: capitalize;
  // height: 30px !important;
  // min-width: 105px !important;
}
.paid {
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  color: #04b300 !important;
}
.failure {
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 600;
  color: #ff0000 !important;
}
.unpaid {
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 600;
  color: #ff0000 !important;
}
.btn-cancel {
  height: 30px !important;
  min-width: 64px !important;
}
.iconsSet {
  cursor: pointer;
}
.goneText {
  display: none;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent !important;
}
.theme--light.v-expansion-panels.v-expansion-panels--focusable
  .v-expansion-panel-header--active::before {
  opacity: 0 !important;
}
.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border-color: transparent !important;
}
.section-expand {
  padding-bottom: 8px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 10px !important;
}
.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px !important;
}
.head-vir {
  background: #1a428a;
  color: #fff;
}

.head-vir2 {
  background: #6dc6d6;
  font-weight: bold;
}

.head-vir2:hover {
  background: #6dc6d6 !important;
}

.head-vir2 th {
  color: #000 !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}
.href-link {
  color: #1f3c87;
  font-weight: 600;
}
.uppercase-input input,
.v-input {
  text-transform: capitalize !important;
}
.button-rating {
  cursor: pointer;
  background-color: #012257;
  color: white;
  height: 25px;
  width: 85px;
  text-align: center;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px 0px 0px 5px;
}
.button-rating-sm {
  cursor: pointer;
  background-color: #012257;
  color: white;
  height: 25px;
  width: 85px;
  text-align: center;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
}
.button-rating-done {
  background-color: #012257;
  color: white;
  height: 25px;
  width: 85px;
  text-align: center;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px 0px 0px 5px;
}
.button-rating-done-sm {
  background-color: #012257;
  color: white;
  height: 25px;
  width: 85px;
  text-align: center;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
}
.button-irisan {
  background-color: #012257;
  padding: 0px 12px 12px 12px;
  color: white;
  height: 25px;
  width: 85px;
}
.button-transparent {
  background-color: white;
  padding: 18px 25px 0px 12px;
  color: white;
  height: 25px;
  width: 85px;
  border-radius: 0px 14px 0px 0px;
}
.border-5 {
  padding-top: 20px;
}
@media screen and (max-width: 912px) {
  .border-side {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .border-top {
    padding-top: 0px !important;
  }
  .order-number {
    padding-top: 0px !important;
  }
  .show-btn-nav {
    display: none !important;
  }
  .hide-btn-nav {
    display: block !important;
  }
  .button-action-set {
    padding-bottom: 16px !important;
  }
  .top-mobile-none {
    padding-top: 0px !important;
  }
  .bottom-mobile-none {
    padding-bottom: 0px !important;
  }
}
@media screen and (min-width: 913px) {
  .border-5 {
    padding-top: 0px;
  }
  .border-side {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  .show-btn-nav {
    display: block;
  }
  .hide-btn-nav {
    display: none;
  }
  .button-action-set {
    // padding-top: 15px !important;
    padding-bottom: 11px !important;
    padding-right: 11px !important;
  }
  .delete-padding-left {
    padding-left: 0px !important;
  }
  .delete-padding-top {
    padding-top: 0px !important;
  }
}
@media screen and (min-width: 1026px) {
  .border-side {
    padding-left: 0px !important;
  }
  .border-5 {
    padding-top: 15px;
  }
  .delete-padding-top {
    padding-top: 12px !important;
  }
}
.top-40 {
  margin-top: 40px !important;
}
.set-image-bottom {
  padding-bottom: 12px !important;
}
.set-image-bottom-active {
  padding-bottom: 0px !important;
}

.card-cart {
  width: auto;
  height: 60px;
}

@media only screen and (max-width: 600px) {
  .card-cart {
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100% !important;
    left: 0;
    right: 0;
  }

  .text-field {
    margin-left: 10px !important;
    max-width: 75%;
  }

  .bell-mobile {
    margin-left: -24px;
  }

  .user-card {
    max-width: 56% !important;
    margin-top: -41px;
    margin-left: 153px;
  }

  .notification {
    height: 55px;
    border-radius: 3px;
    margin-left: -97px;
    margin-top: 4px;
  }

  .cart-right {
    z-index: 1;
    margin-left: -101px;
  }
}

.cart-right {
  margin-left: 17px;
}
.align-end {
  text-align: end;
}
.bottom-none {
  padding-bottom: 0px !important;
}
.top-none {
  padding-top: 0px !important;
}
.column-queue {
  margin-top: -28px;
}
</style>
