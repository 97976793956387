<!-- start modal popup order detail (dialog_order)-->
<template>
  <v-row justify="center">
    <v-dialog
      v-model="is_show"
      content-class="dialogorderdetail"
      width="1000px"
    >
      <v-card class="pt-5">
        <v-card-title class="px-3 px-md-5">
          <v-col cols="6" md="6" no-gutters class="ma-0 pa-0">
            <span class="text-h5">{{ $t("page.profile.lab_result") }}</span>
            <br />
            <!--
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  small
                  color="#FFF"
                  text
                  class="btn-download-report"
                  v-bind="attrs" 
                  v-on="on"
                >
                <v-icon left dark> mdi-download </v-icon>
                  {{ $t("download") }} PDF
              </v-btn>
             </template> 
             <br />
            -->
            <v-menu offset-y v-if="dataDialogOrderMCU.list.length > 0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  color="#FFF"
                  text
                  class="btn-download-report"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left dark> mdi-download </v-icon>
                  {{ $t("download") }} PDF
                </v-btn>
              </template>
              <div class="popup">
                <v-row>
                  <v-col cols="12" class="pa-2 mt5 mb10">
                    <v-btn
                      v-if="dataDialogOrderMCU.type_test == 'LAB'"
                      small
                      color="black"
                      text
                      @click="
                        downloadLab(
                          dataDialogOrderMCU.list[0].dataPatient[0]
                            .order_number,
                          'simple'
                        )
                      "
                    >
                      <v-icon left dark> mdi-file-document </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("simple") }}</span>
                        <span class="title-grid-c">{{
                          $t("page.profile.desc_simple")
                        }}</span>
                      </div>
                    </v-btn>
                    <v-btn
                      v-if="dataDialogOrderMCU.type_test == 'BUY PRODUCT'"
                      small
                      color="black"
                      text
                      @click="
                        downloadLab(
                          dataDialogOrderMCU.list[0].dataPatient[0]
                            .order_number,
                          'simple'
                        )
                      "
                    >
                      <v-icon left dark> mdi-file-document </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("simple") }}</span>
                        <span class="title-grid-c">{{
                          $t("page.profile.desc_simple")
                        }}</span>
                      </div>
                    </v-btn>
                    <v-btn
                      v-if="dataDialogOrderMCU.type_test == 'MCU'"
                      small
                      color="black"
                      text
                      @click="
                        downloadMcu(
                          dataDialogOrderMCU.list[0].dataPatient[0]
                            .order_number,
                          'simple'
                        )
                      "
                    >
                      <v-icon left dark> mdi-file-document </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("simple") }}</span>
                        <span class="title-grid-c">{{
                          $t("page.profile.desc_simple")
                        }}</span>
                      </div>
                    </v-btn>
                    <v-btn
                      v-if="dataDialogOrderMCU.type_test == 'BUY PACKAGE'"
                      text
                      small
                      color="black"
                      @click="
                        downloadMcu(
                          dataDialogOrderMCU.list[0].dataPatient[0]
                            .order_number,
                          'simple'
                        )
                      "
                    >
                      <v-icon left dark> mdi-file-document </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("simple") }}</span>
                        <span class="title-grid-c">{{
                          $t("page.profile.desc_simple")
                        }}</span>
                      </div>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="pa-2 mb5">
                    <v-btn
                      v-if="dataDialogOrderMCU.type_test == 'LAB'"
                      small
                      color="black"
                      text
                      @click="
                        downloadLab(
                          dataDialogOrderMCU.list[0].dataPatient[0]
                            .order_number,
                          'advance'
                        )
                      "
                    >
                      <v-icon left dark color="black">
                        mdi-chart-bar-stacked
                      </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("advance") }}</span>
                        <span class="title-grid-c">{{
                          $t("page.profile.desc_advance")
                        }}</span>
                      </div>
                    </v-btn>
                    <v-btn
                      v-if="dataDialogOrderMCU.type_test == 'BUY PRODUCT'"
                      small
                      color="black"
                      text
                      @click="
                        downloadLab(
                          dataDialogOrderMCU.list[0].dataPatient[0]
                            .order_number,
                          'advance'
                        )
                      "
                    >
                      <v-icon left dark color="black">
                        mdi-chart-bar-stacked
                      </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("advance") }}</span>
                        <span class="title-grid-c">{{
                          $t("page.profile.desc_advance")
                        }}</span>
                      </div>
                    </v-btn>
                    <v-btn
                      v-if="dataDialogOrderMCU.type_test == 'MCU'"
                      small
                      color="black"
                      text
                      @click="
                        downloadMcu(
                          dataDialogOrderMCU.list[0].dataPatient[0]
                            .order_number,
                          'advance'
                        )
                      "
                    >
                      <v-icon left dark color="black">
                        mdi-chart-bar-stacked
                      </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("advance") }}</span>
                        <span class="title-grid-c">{{
                          $t("page.profile.desc_advance")
                        }}</span>
                      </div>
                    </v-btn>
                    <v-btn
                      v-if="dataDialogOrderMCU.type_test == 'BUY PACKAGE'"
                      text
                      small
                      color="black"
                      @click="
                        downloadMcu(
                          dataDialogOrderMCU.list[0].dataPatient[0]
                            .order_number,
                          'advance'
                        )
                      "
                    >
                      <v-icon left dark color="black">
                        mdi-chart-bar-stacked
                      </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("advance") }}</span>
                        <span class="title-grid-c">{{
                          $t("page.profile.desc_advance")
                        }}</span>
                      </div>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-menu>
            <!--
            <template v-if="dataDialogOrderMCU.list.length > 0">
              <v-btn
                v-if="dataDialogOrderMCU.type_test == 'LAB'"
                small
                color="#FFF"
                text
                @click="
                  downloadLab(
                    dataDialogOrderMCU.list[0].dataPatient[0].order_number
                  )
                "
                class="btn-download-report"
              >
                <v-icon left dark> mdi-download </v-icon>
                {{ $t("download") }} PDF
              </v-btn>
              <v-btn
                v-if="dataDialogOrderMCU.type_test == 'BUY PRODUCT'"
                small
                color="#FFF"
                text
                @click="
                  downloadLab(
                    dataDialogOrderMCU.list[0].dataPatient[0].order_number
                  )
                "
                class="btn-download-report"
              >
                <v-icon left dark> mdi-download </v-icon>
                {{ $t("download") }} PDF
              </v-btn>
              <v-btn
                v-if="dataDialogOrderMCU.type_test == 'MCU'"
                small
                color="#FFF"
                text
                @click="
                  downloadMcu(
                    dataDialogOrderMCU.list[0].dataPatient[0].order_number
                  )
                "
                class="btn-download-report"
              >
                <v-icon left dark> mdi-download </v-icon>
                {{ $t("download") }} PDF
              </v-btn>
              <v-btn
                v-if="dataDialogOrderMCU.type_test == 'BUY PACKAGE'"
                text
                small
                color="#FFF"
                @click="
                  downloadMcu(
                    dataDialogOrderMCU.list[0].dataPatient[0].order_number
                  )
                "
                class="btn-download-report"
              >
                <v-icon left dark> mdi-download </v-icon>
                {{ $t("download") }} PDF
              </v-btn>
            </template>
            -->
          </v-col>
          <v-col cols="6" md="6" no-gutters class="ma-0 pa-0">
            <img
              src="/iconVirtu.png"
              alt="Icon Virtu"
              class="float-right dialogvirtulogo"
            />
          </v-col>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-3 px-md-5">
          <v-row class="pt-5">
            <v-col cols="12">
              <h3 class="font-black">{{ $t("document_information") }}</h3>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table class="super-dense" mobile-breakpoint="0">
                      <template v-slot:default>
                        <tbody class="no-pad tbl">
                          <tr class="grey-color head-vir2">
                            <th class="text-center tb-wide">
                              {{ $t("field.lab_report_no") }}
                            </th>
                            <th class="text-center tb-wide2">
                              {{ $t("page.profile.inspection_date") }}
                            </th>
                            <th class="text-center tb-wide">
                              {{ $t("field.branch_code") }}
                            </th>
                            <th class="text-center tb-wide2">
                              {{ $t("field.branch_name") }}
                            </th>
                          </tr>

                          <tr
                            class="hover-apri"
                            v-if="dataDialogOrderMCU.list.length > 0"
                          >
                            <td class="text-center">
                              {{
                                dataDialogOrderMCU.list[0].dataPatient[0]
                                  .registration_number
                              }}
                            </td>
                            <td class="text-center">
                              <!-- {{
                                   formatDateTime(dataDialogOrderMCU.list[0].dataPatient[0].inspection_date)
                                }} -->
                              {{
                                formatDateTime(
                                  dataDialogOrderMCU.list[0].dataDiagnostic
                                    .diagnostic.diagnostic_detail[0]
                                    .inspection_date
                                )
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                dataDialogOrderMCU.list[0].dataPatient[0]
                                  .plant_code
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                dataDialogOrderMCU.list[0].dataPatient[0]
                                  .plant_name
                              }}
                            </td>
                          </tr>
                          <tr class="hover-apri" v-else>
                            <td colspan="4" class="text-center">
                              {{ $t("field.nomor_data") }}
                            </td>
                          </tr>
                          <tr class="grey-color head-vir2">
                            <th class="text-center">
                              {{ $t("field.medical_record_no") }}
                            </th>
                            <th class="text-center">
                              {{ $t("field.primary_doctor") }}
                            </th>
                            <th class="text-center">
                              {{ $t("field.gender") }}
                            </th>
                            <th class="text-center">
                              {{ $t("field.lab_chief") }}
                            </th>
                          </tr>
                          <tr
                            class="hover-apri"
                            v-if="dataDialogOrderMCU.list.length > 0"
                          >
                            <td class="text-center">
                              {{
                                dataDialogOrderMCU.list[0].dataPatient[0]
                                  .medical_record_number
                              }}
                            </td>
                            <td class="text-center">
                              <!-- {{
                                dataDialogOrderMCU.list[0].dataPatient[0]
                                  .primary_doctor
                              }} -->
                              {{
                                dataDialogOrderMCU.list[0].dataDiagnostic
                                  .diagnostic.diagnostic_detail[0]
                                  .primary_doctor
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                dataDialogOrderMCU.list[0].dataPatient[0][
                                  $i18n.locale == "id"
                                    ? `gender_name_id`
                                    : `gender`
                                ]
                              }}
                            </td>
                            <td class="text-center">
                              <!-- {{
                                dataDialogOrderMCU.list[0].dataPatient[0]
                                  .lab_chief
                              }} -->
                              {{
                                dataDialogOrderMCU.list[0].dataDiagnostic
                                  .diagnostic.diagnostic_detail[0].lab_chief
                              }}
                            </td>
                          </tr>
                          <tr class="hover-apri" v-else>
                            <td colspan="4" class="text-center">
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>
            </v-col>
          </v-row>
          <v-row> </v-row>
        </v-card-text>
        <v-card-text class="px-3 px-md-5">
          <v-row>
            <v-col cols="12">
              <h3 class="font-black">
                {{ $t("page.cart.customer_information") }}
              </h3>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table class="no-hover-table super-dense">
                      <template v-slot:default>
                        <tbody
                          class="no-pad desktop"
                          v-if="dataDialogOrderMCU.list.length > 0"
                        >
                          <tr class="hover-apri">
                            <td class="font-weight-bold">
                              {{
                                dataDialogOrderMCU.list[0].dataPatient[0]
                                  .patient_name
                              }}
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.mobile_phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        dataDialogOrderMCU.list[0]
                                          .dataPatient[0].celluar !== null
                                          ? dataDialogOrderMCU.list[0]
                                              .dataPatient[0].celluar
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                              
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              {{
                                `${dataDialogOrderMCU.list[0].dataPatient[0].address.toUpperCase()}`
                              }}
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        dataDialogOrderMCU.list[0]
                                          .dataPatient[0].phone_number !== null
                                          ? dataDialogOrderMCU.list[0]
                                              .dataPatient[0].phone_number
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              {{
                                `${dataDialogOrderMCU.list[0].dataPatient[0].city}, ${dataDialogOrderMCU.list[0].dataPatient[0].district}`
                              }}
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.email") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        dataDialogOrderMCU.list[0]
                                          .dataPatient[0].email !== null
                                          ? dataDialogOrderMCU.list[0].dataPatient[0].email.toUpperCase()
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              {{
                                ` ${dataDialogOrderMCU.list[0].dataPatient[0].province} ${dataDialogOrderMCU.list[0].dataPatient[0].postal_code}, ${dataDialogOrderMCU.list[0].dataPatient[0].country}`
                              }}
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.dob") }}</td>
                                  <td>
                                    :{{
                                      formatDate(
                                        dataDialogOrderMCU.list[0]
                                          .dataPatient[0].date_of_birth_format,
                                        "DD MMM YYYY"
                                      ).toUpperCase()
                                    }}
                                    |
                                    {{
                                      dataDialogOrderMCU.list[0].dataPatient[0][
                                        $i18n.locale == "id"
                                          ? `patient_age_id`
                                          : `patient_age`
                                      ].toUpperCase()
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td></td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.mcu_type") }}</td>
                                  <td>
                                    :
                                    {{
                                      dataDialogOrderMCU.list[0].dataPatient[0][
                                        $i18n.locale == "id"
                                          ? `mcu_type_id`
                                          : `mcu_type`
                                      ]
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                        </tbody>
                        <!-- start mobile part -->
                        <tbody
                          class="no-pad mobile"
                          v-if="dataDialogOrderMCU.list.length > 0"
                        >
                          <tr class="hover-apri">
                            <td class="font-weight-bold">
                              {{
                                dataDialogOrderMCU.list[0].dataPatient[0]
                                  .patient_name
                              }}
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              {{
                                `${dataDialogOrderMCU.list[0].dataPatient[0].address.toUpperCase()}`
                              }}
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              {{
                                `${dataDialogOrderMCU.list[0].dataPatient[0].city}, ${dataDialogOrderMCU.list[0].dataPatient[0].district}`
                              }}
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              {{
                                ` ${dataDialogOrderMCU.list[0].dataPatient[0].province} ${dataDialogOrderMCU.list[0].dataPatient[0].postal_code}, ${dataDialogOrderMCU.list[0].dataPatient[0].country}`
                              }}
                            </td>
                          </tr>
                          <!-- <tr class="hover-apri">
                            <td class="font-weight-bold">
                              {{ $t("field.medical_record_no") }} :
                              {{
                                dataDialogOrderMCU.list[0].dataPatient[0]
                                  .medical_record_number
                              }}
                            </td>
                          </tr> -->
                          <tr class="hover-apri">
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.mobile_phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        dataDialogOrderMCU.list[0]
                                          .dataPatient[0].celluar !== null
                                          ? dataDialogOrderMCU.list[0]
                                              .dataPatient[0].celluar
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        dataDialogOrderMCU.list[0]
                                          .dataPatient[0].phone_number !== null
                                          ? dataDialogOrderMCU.list[0]
                                              .dataPatient[0].phone_number
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                              
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.email") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        dataDialogOrderMCU.list[0]
                                          .dataPatient[0].email !== null
                                          ? dataDialogOrderMCU.list[0].dataPatient[0].email.toUpperCase()
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.dob") }}</td>
                                  <td>
                                    :
                                    {{
                                      dataDialogOrderMCU.list[0].dataPatient[0][
                                        $i18n.locale == "id" ? `dob_id` : `dob`
                                      ].toUpperCase()
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>

                          <tr class="hover-apri">
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.mcu_type") }}</td>
                                  <td>
                                    :
                                    {{
                                      dataDialogOrderMCU.list[0].dataPatient[0]
                                        .mcu_type
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr class="hover-apri">
                            <td class="text-center">{{ $t("no_data") }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>
            </v-col>
          </v-row>
          <v-row> </v-row>
        </v-card-text>
        <v-card-text class="px-3 px-md-5">
          <v-row>
            <v-col cols="12">
              <h3 class="font-black">
                {{ $t("page.profile.inspection_result") }}
              </h3>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table class="table-sticky super-dense">
                      <template v-slot:default>
                        <thead class="no-pad">
                          <tr class="grey-color head-vir2">
                            <th class="text-center tb-wide" style="width: 25%">
                              {{ $t("page.profile.inspection_code") }}
                            </th>
                            <th class="text-center tb-wide" style="width: 20%">
                              {{ $t("page.profile.inspection_name") }}
                            </th>
                            <th class="text-center tb-wide" style="width: 15%">
                              {{ $t("result") }}
                            </th>
                            <th class="text-center tb-wide" style="width: 15%">
                              {{ $t("page.profile.reference_result") }}
                            </th>
                            <th class="text-center" style="width: 10%">
                              {{ $t("page.profile.unit") }}
                            </th>
                            <th class="text-center" style="width: 15%">
                              {{ $t("page.profile.notes") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody class="no-pad">
                          <tr
                            v-for="(item, index) in tableFilter"
                            :key="index"
                            :class="{
                              'grey-color': item.type == 'header',
                              'hover-apri': item.type == 'test',
                            }"
                          >
                            <td
                              v-if="item.type == 'category'"
                              colspan="6"
                              class="category-blue"
                            >
                              {{ item.text }}
                            </td>
                            <td
                              v-else-if="item.type == 'subcategory'"
                              colspan="6"
                              class="grey-color bold"
                            >
                              {{ item.text }}
                            </td>
                            <template v-else>
                              <td
                                class="text-left td-custom"
                                :class="{
                                  true: item.critical_status == true,
                                  false: item.critical_status == false,
                                }"
                              >
                                <v-icon
                                  color="red"
                                  small
                                  v-if="item.critical_status"
                                  style="font-size: 12px !important"
                                >
                                  mdi-alert
                                </v-icon>
                                {{ item.product_item_code }}
                              </td>
                              <td class="text-left">
                                {{ item.product_item_name }}
                              </td>
                              <td
                                class="text-right"
                                :class="{
                                  true: item.critical_status == true,
                                  false: item.critical_status == false,
                                }"
                              >
                                {{ item.result_value_post }}
                              </td>
                              <td class="text-right">
                                {{ formaterText(item.reference_text) }}
                              </td>
                              <td class="text-right">
                                {{ item.item_unit }}
                              </td>
                              <td class="text-left">
                                {{ item.status }}
                              </td>
                            </template>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>
            </v-col>
          </v-row>
          <v-row> </v-row>
        </v-card-text>
        <v-card-text class="px-3 px-md-5">
          <v-row>
            <v-col cols="12">
              <h3 class="font-black">{{ $t("result") }}</h3>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody
                          class="no-pad desktop"
                          v-if="dataDialogOrderMCU.list.length > 0"
                        >
                          
                          <tr class="hover-apri"  v-for="(item, index) of dataDialogOrderMCU.list[0].dataResult.detail_recommendation" :key="index">
                            <td>
                              {{
                                `${
                                  $i18n.locale == "id" ? item.description : item[`description_${$i18n.locale}`] == null ? "-" : item[`description_${$i18n.locale}`]
                                }`
                              }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody
                          class="no-pad mobile"
                          v-if="dataDialogOrderMCU.list.length > 0"
                        >
                        <tr class="hover-apri"  v-for="(item, index) of dataDialogOrderMCU.list[0].dataResult.detail_recommendation" :key="index">
                            <td>
                              {{
                                `${
                                  $i18n.locale == "id" ? item.description : item[`description_${$i18n.locale}`] == null ? "-" : item[`description_${$i18n.locale}`]
                                }`
                              }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr class="hover-apri">
                            <td class="text-center">{{ $t("no_data") }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>
            </v-col>
          </v-row>
          <v-row> </v-row>
        </v-card-text>
        <v-card-text class="px-3 px-md-5">
          <v-row>
            <v-col cols="12">
              <h3 class="font-black">{{ $t("recomendation") }}</h3>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody
                          class="no-pad desktop"
                          v-if="dataDialogOrderMCU.list.length > 0"
                        >
                          <tr class="hover-apri"  v-for="(item, index) of dataDialogOrderMCU.list[0].dataResult.recommendation" :key="index">
                            <td>
                              {{
                                `${
                                  $i18n.locale == "id" ? item.text : item[`text_${$i18n.locale}`] == null ? "-" : item[`text_${$i18n.locale}`]
                                }`
                              }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody
                          class="no-pad mobile"
                          v-if="dataDialogOrderMCU.list.length > 0"
                        >
                         
                          <tr class="hover-apri"  v-for="(item, index) of dataDialogOrderMCU.list[0].dataResult.recommendation" :key="index">
                            <td>
                              {{
                                `${
                                  $i18n.locale == "id" ? item.text : item[`text_${$i18n.locale}`] == null ? "-" : item[`text_${$i18n.locale}`]
                                }`
                              }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr class="hover-apri">
                            <td class="text-center">{{ $t("no_data") }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>
            </v-col>
          </v-row>
          <v-row> </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary darken-1" text @click="toggleDialog()">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Card from "@/components/v2/card/index.vue";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
// import icons from "@/components/elements/Icons.vue";
import moment from "moment";

export default {
  components: {
    Card,
    // icons,
  },
  props: {
    tampilkan: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => [],
    },
    tableFilter: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      is_show: false,
      dataDialogOrderMCU: null,
    };
  },
  created() {
    this.is_show = this.tampilkan;
    this.dataDialogOrderMCU = this.item;
  },
  watch: {
    tampilkan: function (new_val, old_val) {
      if (old_val !== "") {
        this.is_show = new_val;
      }
    },
    is_show: function (new_val, old_val) {
      if (old_val !== "") {
        if (!new_val) {
          this.$emit("toggleDialog", this.is_show);
        }
      }
    },
  },
  methods: {
    toggleDialog() {
      this.is_show = false;
      this.$emit("toggleDialog", this.is_show);
    },
    downloadLab(param, type) {
      API.get(
        `${process.env.VUE_APP_API_TRUST}diagnosis/get-labreport?order_number=${param}&type=${type}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
        this.download = res;
        window.open(this.download.linkDownload, "_blank");
      });
    },
    downloadMcu(param, type) {
      API.get(
        `${process.env.VUE_APP_API_TRUST}diagnosis/get-mcureport?order_number=${param}&type=${type}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
        this.download = res;
        window.open(this.download.linkDownload, "_blank");
      });
    },
    formaterText(param) {
      if (param == null) return "-";
      let result = param.substr(0, 1);
      if (result == "[") {
        let newVal = JSON.parse(param);
        let newValues = newVal[0].reference_text;

        return newValues;
      } else {
        return param;
      }
    },
    formatDateTime(par) {
      if (!par) return null;
      return moment(par)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY HH:mm:ss")
        .toUpperCase();
    },
    formatDate(date, format) {
      return moment(date).locale(this.$i18n.locale).format(format);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.grey-color {
  background: #eeeeee;
}

.head-vir {
  background: #1a428a;
  color: #fff;
}

.head-vir2 {
  background: #cecece;
  font-weight: bold;
}

.head-vir2:hover {
  background: #cecece !important;
}

.head-vir2 th {
  color: #000 !important;
}

.bold {
  font-weight: bold;
}

// .hover-apri:hover {
//   background: rgba(113, 188, 225, 0.1) !important;
// }
// .hover-apri:hover td {
//   font-weight: bold;
// }

.v-data-table table tr td {
  font-size: 12px !important;
}

.mobile {
  display: none;
}
@media (min-width: 539px) {
  .v-menu__content {
    max-width: 43%;
  }
}

@media (min-width: 750px) {
  .v-menu__content {
    max-width: 30%;
  }
}
@media (min-width: 810px) {
  .v-menu__content {
    max-width: 33%;
  }
}
@media (min-width: 1000px) {
  .v-menu__content {
    max-width: 25%;
  }
}
@media (min-width: 1030px) {
  .v-menu__content {
    max-width: 20%;
  }
}
@media (max-width: 450px) {
  .v-menu__content {
    max-width: 65%;
  }
  .dialogvirtu.v-dialog {
    margin: 5px !important;
  }
  .tb-wide {
    min-width: 150px;
  }
  .tb-wide2 {
    min-width: 250px;
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }

  .dialogvirtulogo {
    width: 150px;
  }
}
</style>
<style lang="scss">
@media (max-width: 450px) {
  .dialogorderdetail.v-dialog {
    margin: 5px !important;
  }
  .dialogorderdetail .v-card__text {
    padding: 0 10px 20px !important;
  }
  .td-custom {
    width: 100% !important;
  }
}
.font-black {
  color: #000 !important;
}
.category-black {
  color: #fff !important;
  background: #000 !important;
}
.category-blue {
  color: #fff !important;
  background: #233e8d !important;
}
.btn-download-report {
  background: #209fb4;
}

.table-inside {
  width: 100%;
  padding: 0;
}

.table-inside tr td:first-child {
  width: 20%;
}

.mobile tr {
  display: inline-table;
  width: 100%;
}

.mobile .table-inside tr td:first-child {
  width: 20%;
}

.td-custom {
  width: 14%;
}

@media (min-width: 600px) {
  .table-sticky .v-data-table__wrapper {
    overflow: unset;
  }
  .table-sticky thead {
    position: sticky;
    inset-block-start: 0;
    top: 0;
  }
}
@media (max-width: 599px) {
  .table-sticky .v-data-table__wrapper {
    overflow-x: auto;
    overflow-y: auto;
    height: 400px;
  }
  .table-sticky thead tr {
    border-bottom: 0 !important;
    position: sticky;
    top: 0;
    z-index: 2;
  }
}
.true {
  color: #f44336 !important;
  font-weight: 600;
}
.popup {
  padding: 8px;
  background: white;
  text-transform: uppercase;
  font-size: 12px !important;
  line-height: 16px;
  a {
    text-decoration: none;
    color: black;
  }
}
.title-grid {
  display: grid;
}
.title-grid-h {
  text-align: left;
}
.title-grid-c {
  text-align: left;
  font-size: 8px;
}
.mt5 {
  margin-top: 5px !important;
}
.mb10 {
  margin-bottom: -10px;
}
.mb5 {
  margin-bottom: 5px !important;
}
</style>
